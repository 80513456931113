import { richText, stripRichTextWrapperTag } from '@/utils/html';
import { adapter as imageAdapter } from '@/components/cloud-image/utils';
import { routerLinks } from '@/utils/fetch-links';

const CTAImage = (link, img, item) => {
  return !!link && !!img.filename
    ? {
        link,
        withShadow: item.cta_image_shadow,
        backgroundMobile: item.cta_image_background_color_mobile,
        backgroundDesktop: item.cta_image_background_color_desktop,
        img: {
          ...img,
          width: 140,
          height: 50,
        },
      }
    : undefined;
};

export default async (
  { primary },
  { i18n, prismic, enhancedLinkSerializer, seoLevel }
) => {
  const lang = i18n.localeProperties.iso;
  const module = await prismic.api.getByID(primary?.banner?.id, {
    fetchLinks: routerLinks,
    lang,
  });
  const moduleData = module?.data;

  if (!moduleData) {
    return null;
  }

  let needfetch = false;

  const items = moduleData.banner_entries.map((entry) => {
    const CTAImages = [
      CTAImage(
        enhancedLinkSerializer(entry.cta_image_1_link),
        imageAdapter(entry.cta_image_1_image, entry.cta_image_1_alt),
        entry
      ),
      CTAImage(
        enhancedLinkSerializer(entry.cta_image_2_link),
        imageAdapter(entry.cta_image_2_image, entry.cta_image_2_alt),
        entry
      ),
    ].filter((image) => image !== undefined);

    const sanitized = {
      primaryColor: entry.primary_color,
      label: entry.banner_label,
      title: stripRichTextWrapperTag(entry.banner_title),
      text: richText(entry.banner_description),
      imageMobile: imageAdapter(
        entry.banner_image_mobile,
        entry.banner_image_alt
      ),
      imageDesktop: imageAdapter(
        entry.banner_image_desktop,
        entry.banner_image_alt
      ),
      ...(!!CTAImage.length && { ctaImages: CTAImages }),
    };

    if (entry.banner_link) {
      // Check if link is using market or product to force module fetch
      if (entry.banner_link.link_type === 'Document') {
        needfetch = true;
      }

      if (entry.banner_link_rel) {
        entry.banner_link.rel = entry.banner_link_rel;
      }

      sanitized.cta = {
        label: entry.banner_link_label || i18n.t('cta.see-all'),
        link: enhancedLinkSerializer(entry.banner_link),
      };
    }

    return sanitized;
  });

  // Fetch module again to have all the needed data to generate links
  if (needfetch) {
    const banner = await prismic.api.getByID(primary.banner.id, {
      fetchLinks: routerLinks,
      lang,
    });

    if (banner && banner?.data?.banner_entries) {
      banner.data.banner_entries.forEach((entry, index) => {
        if (entry.banner_link) {
          if (entry.banner_link_rel) {
            entry.banner_link.rel = entry.banner_link_rel;
          }

          items[index].cta.link = enhancedLinkSerializer(entry.banner_link);
        }
      });
    }
  }

  return {
    title:
      moduleData.sectionTitle &&
      stripRichTextWrapperTag(moduleData.sectionTitle),
    narrow: moduleData.is_narrow,
    items,
  };
};
